import {alias, define, singleton} from '@injex/core';
import dayjs from 'dayjs';
import {TimeRangeType} from '../common/enums';
import ITimeRange, {TimeRangeResolve, TimeRangeResolveCompare} from '../interfaces/ITimeRange';

@define()
@singleton()
@alias('TimeRange')
export class LastWeekTimeRange implements ITimeRange {
    public readonly rangeType: TimeRangeType = TimeRangeType.LastWeek;
    public readonly order: number = 50;
    public readonly displayName: string = 'Last Week';

    public resolve(): TimeRangeResolve {
        const [from, to] = [
            dayjs().utc().subtract(1, 'week').startOf('week'),
            dayjs().utc().startOf('week')
        ];

        return [
            from.unix(),
            to.unix(),
            `${from.format('DD.MM')}-${to.format('DD.MM')}`
        ];
    }

    public resolveCompare(): TimeRangeResolveCompare {
        const [compareFrom, compareTo] = [
            dayjs().utc().subtract(2, 'week').startOf('week'),
            dayjs().utc().subtract(1, 'week').startOf('week')
        ];

        return [
            compareFrom.unix(),
            compareTo.unix(),
            `${compareFrom.format('DD.MM')}-${compareTo.format('DD.MM')}`
        ];
    }
}