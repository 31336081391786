import {alias, define, singleton} from '@injex/core';
import dayjs from 'dayjs';
import {TimeRangeType} from '../common/enums';
import ITimeRange, {TimeRangeResolve, TimeRangeResolveCompare} from '../interfaces/ITimeRange';

@define()
@singleton()
@alias('TimeRange')
export class MonthTimeRange implements ITimeRange {
    public readonly rangeType: TimeRangeType = TimeRangeType.ThisMonth;
    public readonly order: number = 60;
    public readonly displayName: string = 'This Month';

    public resolve(): TimeRangeResolve {
        const [from, to] = [
            dayjs().utc().startOf('month'),
            dayjs().utc().add(1, 'day').startOf('day')
        ];

        return [
            from.unix(),
            to.unix(),
            from.format('MM.YYYY')
        ];
    }

    public resolveCompare(): TimeRangeResolveCompare {
        const [compareFrom, compareTo] = [
            dayjs().utc().subtract(1, 'month').startOf('month'),
            dayjs().utc().subtract(1, 'month').endOf('hour')
        ];

        return [
            compareFrom.unix(),
            compareTo.unix(),
            `${compareFrom.format('MM.YYYY')} (Last Month)`
        ];
    }
}