import {alias, define, singleton} from '@injex/core';
import dayjs from 'dayjs';
import {TimeRangeType} from '../common/enums';
import ITimeRange, {TimeRangeResolve, TimeRangeResolveCompare} from '../interfaces/ITimeRange';

@define()
@singleton()
@alias('TimeRange')
export class LastYearTimeRange implements ITimeRange {
    public readonly rangeType: TimeRangeType = TimeRangeType.LastYear;
    public readonly order: number = 100;
    public readonly displayName: string = 'Last Year';

    public resolve(): TimeRangeResolve {
        const [from, to] = [
            dayjs().utc().subtract(1, 'year').startOf('year'),
            dayjs().utc().startOf('year')
        ];

        return [
            from.unix(),
            to.unix(),
            from.format('YYYY')
        ];
    }

    public resolveCompare(): TimeRangeResolveCompare {
        return [0, 0, ''];
    }
}